<i18n src="@i18n/drone/part.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
page.title : "อะไหล่"
page.description : "แสดงและค้นหารายการอะไหล่ที่อยู่ในระบบ"


part.search.keyword_sku.placeholder : "ค้นหาจาก SKU"
part.search.keyword_name.placeholder : "ค้นหาจากชื่อ (EN)"
part.search.keyword_name_th.placeholder : "ค้นหาจากชื่อ (TH)"
part.search.part_type : "@:part.field.part_type"
part.search.status : "สถานะ"
part.search.has_serial_no : "อะไหล่มี Serial No"
part.search.has_serial_no.yes : "ต้องการ"
part.search.has_serial_no.no : "ไม่ต้องการ"
part.search.strict_use : "อะไหล่ที่ต้องระบุรุ่น"
part.search.strict_use.yes : "จำเป็น"
part.search.strict_use.no : "ไม่จำเป็น"

button.create_part : "เพิ่มอะไหล่ใหม่"
button.import_part : "Import"
button.export_part : "Export"
</i18n>

<template>
	<div class="page page-padding page-fit-mobile">
		<my-page-header
			:title="$t('page.title')"
			:description="$t('page.description')"/>
		<div v-if="canImportPart && canCreatePart" class="page-action-right">
			<a-button
				v-if="canCreatePart"
				class="btn-page-action"
				type="primary"
				icon="plus"
				@click="clickCreatePart">
				{{$t('button.create_part')}}
			</a-button>
			<a-button
				v-if="canImportPart"
				icon="upload"
				@click="clickImportPart">
				{{$t('button.import_part')}}
			</a-button>
			<a-button
				v-if="canExportPart"
				icon="download"
				@click="clickExportPart">
				{{$t('button.export_part')}}
			</a-button>

		</div>
		<div class="myform-action-pane">
			<a-form layout="inline">
				<a-form-item>
					<a-input v-model="filter.keywordSKU"
						:placeholder="$t('part.search.keyword_sku.placeholder')"
						style="width:250px;"
						allow-clear >
						<a-icon slot="suffix" type="search" />
					</a-input>
				</a-form-item>
				<a-form-item>
					<a-input v-model="filter.keywordName"
						:placeholder="$t('part.search.keyword_name.placeholder')"
						style="width:250px;"
						allow-clear >
						<a-icon slot="suffix" type="search" />
					</a-input>
				</a-form-item>
				<a-form-item>
					<a-input v-model="filter.keywordNameTh"
						:placeholder="$t('part.search.keyword_name_th.placeholder')"
						style="width:250px;"
						allow-clear >
						<a-icon slot="suffix" type="search" />
					</a-input>
				</a-form-item>
				<a-form-item :label="$t('part.search.part_type')">
					<a-select
						v-model="filter.partType"
						:get-popup-container="getPopupContainer"
						style="width: 120px"
						:options="partTypeOptions"/>
				</a-form-item>
				<a-form-item class="myform-expandable-row">
					<ExpanableLink v-model="searchMore"
						class="search-more-link ant-form-text"
						:title="$t('common.search.more_options')"/>
				</a-form-item>
				<CollapseTransition>
					<div v-show="searchMore" class="myform-expandable-pane">
						<a-form-item :label="$t('part.search.status')">
							<a-select
								v-model="filter.status"
								:get-popup-container="getPopupContainer"
								style="width: 120px"
								:options="partStatusOptions"/>
						</a-form-item>

						<a-form-item :label="$t('part.search.has_serial_no')">
							<a-select
								v-model="filter.hasSerialNo"
								:get-popup-container="getPopupContainer"
								style="width: 120px"
								:options="hasSerialNumberOptions"/>
						</a-form-item>
						<a-form-item :label="$t('part.search.strict_use')">
							<a-select
								v-model="filter.strictUse"
								:get-popup-container="getPopupContainer"
								style="width: 120px"
								:options="strictUseOptions"/>
						</a-form-item>
					</div>
				</CollapseTransition>

			</a-form>
		</div>
		<a-card
			:bordered="false">
			<a-table
				bordered
				class="mytable mytable-scroll part-table"
				:row-key="record => record.id"
				:data-source="parts"
				:pagination="pagination"
				:loading="loading"
				size="small"
				@change="handleTableChange">
				<a-table-column
					:title="$t('common.table.column.no')"
					align="center"
					class="column-row-no">
					<template slot-scope="text,record,index">
						{{index + 1 + ((pagination.current - 1) * pagination.pageSize)}}
					</template>
				</a-table-column>
				<a-table-column
					key="sku"
					:sorter="true"
					:title="$t('part.field.sku')" data-index='sku'
					:width="150"/>
				<a-table-column
					key="nameTh"
					:title="$t('common.table.column.detail')" data-index="name"
					:sorter="true">
					<template slot-scope="name,record">
						<div class="mylayout-left-right part-detail">
							<div class="mylayout-left">
								<ImageLoader
									:src="record.image_url"
									border-type="round"
									size="small">
									<a-icon type="deployment-unit" />
								</ImageLoader>
							</div>
							<div class="mylayout-right">
								<div>{{record.nameTh}}</div>
								<div class="table-description">
									<b>{{$t('part.field.name_en')}} : </b>
									{{record.name}}
								</div>
								<div class="part-details-tags">
									<MyTooltip
										v-if="record.hasSerialNo"
										:title="$t('part.field.has_serial_no.tooltip')">
										<a-tag  color="#ffb7b2">Serial</a-tag>
									</MyTooltip>
									<MyTooltip
										v-if="record.strictUse"
										:title="$t('part.field.strict_use.tooltip')">
										<a-tag color="#c7ceea">Match Model</a-tag>
									</MyTooltip>
									<MyTooltip
										v-if="record.status === 'inactive'"
										:title="$t('part.field.status.inactive.tooltip')">
										<a-tag color="#bfbfbf">Inactive</a-tag>
									</MyTooltip>
								</div>
							</div>
						</div>
					</template>
				</a-table-column>

				<a-table-column
					key="partType"
					:title="$t('part.field.part_type')" data-index='partType'
					:width="120"
					:sorter="true">
					<template slot-scope="partType">
						{{$tenum('part_type',partType)}}
					</template>
				</a-table-column>

				<a-table-column
					key="pricePerUnit"
					:title="$t('part.field.price_per_unit')" data-index='pricePerUnit'
					align="right"
					:width="120"
					:sorter="true">
					<template slot-scope="pricePerUnit">
						{{pricePerUnit | price}}
					</template>
				</a-table-column>


				<a-table-column
					data-index="remark"
					:width="50"
					align="center">
					<template slot-scope="remark">
						<MyTooltip v-if="$notEmpty(remark)"
							:destroy-tooltip-on-hide="true">
							<div slot="title" v-trim class="multiline">
								{{remark}}
							</div>
							<a-icon type="message"/>
						</MyTooltip>
					</template>
				</a-table-column>
				<a-table-column
					v-if="canReadPart"
					class="mytable-action-col"
					:width="75"
					align="center"
					:title="$t('common.table.column.action')">
					<template slot-scope="record">
						<my-router-link v-if="canReadPart" name="part/view" :param-value="record.id" auth-action="read" auth-module="part">
							<a-icon type="search" />
						</my-router-link>
						<a-divider v-if="canUpdatePart" type="vertical"/>
						<my-router-link v-if="canUpdatePart" name="part/update" :param-value="record.id" auth-action="update" auth-module="part">
							<a-icon type="edit" />
						</my-router-link>
					</template>
				</a-table-column>
			</a-table>
		</a-card>
	</div>
</template>

<script>
import MyTooltip from "@components/common/MyTooltip.vue"
import ExpanableLink from "@components/common/ExpandableLink.vue"
import PageMixin from "@mixins/PageMixin.vue"
import ImageLoader from "@components/common/ImageLoader.vue"
import axios from "axios"
import {Table,Tag,Select,Divider} from "ant-design-vue"
import debounce from "lodash/debounce"
import ApiError from '@utils/errors/ApiError'
import fileDownload from "js-file-download"
import { CollapseTransition } from 'vue2-transitions'
import PopupMixin from "@mixins/PopupMixin.vue"
import { getEnumSelectOptions } from '@utils/formUtil'
import {PART_TYPE_FILTER, PART_EXTRA_FILTER, PART_STATUS_FILTER} from '@utils/serviceUtil'

export default {
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	components : {
		ImageLoader, MyTooltip, CollapseTransition ,ExpanableLink,
		"a-table" : Table,
		"a-table-column" : Table.Column,
		"a-tag" : Tag,
		"a-select" : Select,
		"a-divider" : Divider,
	} ,
	mixins : [PageMixin,PopupMixin],
	data() {
		return {
			searchMore : false,
			parts : [] ,
			pagination : {
				pageSize : 10 ,
				current : 1,
				total : 0,
				hideOnSinglePage : true,
			} ,
			sort : {
				field : null,
				order : null,
			} ,
			filter : {
				keywordName : null,
				keywordNameTh : null,
				keywordSKU: null,
				partType : PART_TYPE_FILTER.ALL,
				status : PART_STATUS_FILTER.ALL,
				hasSerialNo : PART_EXTRA_FILTER.ALL,
				strictUse : PART_EXTRA_FILTER.ALL,
			} ,
			loading: false,
		}
	} ,
	computed : {
		canReadPart() {
			return this.$authorize('read','part');
		} ,
		canUpdatePart() {
			return this.$authorize('update','part');
		} ,
		canImportPart() {
			return this.$authorize('import','part');
		} ,
		canCreatePart() {
			return this.$authorize('create','part')
		} ,
		canExportPart() {
			return this.$authorize('export','part')
		} ,
		partTypeOptions() {
			const options = Object.values(PART_TYPE_FILTER)
			return getEnumSelectOptions(this, 'part_type', options)
		},
		partStatusOptions() {
			const options = Object.values(PART_STATUS_FILTER)
			return getEnumSelectOptions(this, 'part_status', options)
		},
		hasSerialNumberOptions() {
			const options = Object.values(PART_EXTRA_FILTER)
			return getEnumSelectOptions(this, 'part_has_serial_no', options)
		},
		strictUseOptions() {
			const options = Object.values(PART_EXTRA_FILTER)
			return getEnumSelectOptions(this, 'part_strict_use', options)
		}
	} ,
	watch: {
		filter : {
			handler() {
				this.debouceSearchChange();
			} ,
			deep: true,
		}
	} ,
	created() {
		this.debouceSearchChange = debounce(this.handleSearchChange,300);
	} ,
	mounted() {
		this.fetchData();
	} ,
	methods : {
		clickImportPart() {
			this.$router.push({name:'part/import'})
		} ,
		clickExportPart() {
			this.showPageLoading(true)
			axios.get("/api/parts/export",{
				responseType: 'blob'
			}).then((response) => {
				fileDownload(response.data,"parts.xlsx");
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error));
			}).finally(() => {
				this.hidePageLoading()
			})
		} ,
		clickCreatePart() {
			this.$router.push({name:'part/create'})
		} ,
		handleSearchChange() {
			// Reset Pagination
			this.pagination.current = 1;
			this.fetchData();
		} ,

		fetchData(toPage=null) {
			if (toPage === null)
				toPage = this.pagination.current
			const search = {
				pageSize : this.pagination.pageSize ,
				currentPage : toPage,
				sortField : this.sort.field ,
				sortOrder : this.sort.order ,
			}

			this.loading = true;
			axios.get("/api/parts/search",{params : {search:search,filter:this.filter}}).then((response)=>{
				const page = response.data.data.pagination;
				this.parts = response.data.data.parts
				this.pagination.current = toPage;
				this.pagination.total = page.total;
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error));
			}).finally(() => {
				this.loading = false;
			})
		} ,
		handleTableChange(pagination,filters,sorter) {
			this.sort.field = sorter.order === undefined ? null : sorter.columnKey
			this.sort.order = sorter.order;
			this.fetchData(pagination.current);
		}
	}
}
</script>
<style lang="less" scoped>
	.part-detail {
		align-items: center;
	}
	.part-details-tags {
		margin-top : 12px;
	}
</style>
